import React, { useEffect, useState } from 'react'

export default function FileUpload({
    setFiles,
    accept = [],
    fileRef = `${+new Date()}`,
    setIsloading
}) {


    const [acceptString, setAcceptString] = useState('');

    const uploadHandler = async (event) => {
        const files = event.target.files;
        const filesArry = [];
        setIsloading && setIsloading(true);
        for (const file of files) {
            file.id = `${+new Date()}`;
            file.isUploading = true;
            const result = await readFileAsync(file)
            file.isUploading = false;
            if (result) {
                file.data = result;
            }
            filesArry.push(file);
        }
        setTimeout(() => {
            setIsloading && setIsloading(false);
            setFiles(filesArry);
        }, 300)
    }

    async function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            const idxDot = file.name.lastIndexOf('.') + 1;
            const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
            if (accept.includes(extFile)) {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve({
                        url: reader.result,
                        extFile
                    });
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            } else {
                alert(`รองรับเฉพาะไฟล์ประเภท ${accept}`);
                resolve();
            }
        });
    }

    const handleOpenFile = () => {
        document.getElementById(fileRef).click()
    }

    useEffect(() => {
        if (accept) {
            let acceptString = '';
            accept.forEach(type => {
                acceptString += `.${type}, `
            });
            setAcceptString(acceptString);
        }
    }, [accept])

    return (
        <>
            <button
                type="button"
                onClick={handleOpenFile}
                className="w-full border border-gray-100 text-gray-400 bg-white rounded-md hover:border-amber-300 hover:text-amber-300 transition duration-300 flex flex-col p-4 justify-center items-center focus:outline-none focus:ring-amber-500 focus:border-amber-500 focus:shadow-around-input focus:shadow-amber-500/50">
                <div>
                    <svg className="mx-auto h-12 w-12" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className='flex text-sm mt-2'>
                    <label htmlFor="file-upload" className="relative cursor-pointer rounded-md">
                        <span>อัปโหลดไฟล์</span>
                    </label>
                </div>
            </button>
            <input
                id={fileRef}
                className='hidden w-full text-sm text-gray-700 bg-gray-50 rounded-md border border-gray-300 cursor-pointer  focus:ring-indigo-300 focus:border-indigo-300'
                type="file"
                onChange={uploadHandler}
                onClick={(e) => e.target.value = null}
                accept={acceptString}
            />
        </>
    )
}
