import React, { useState } from 'react';

const Checkbox = ({ checked = false, onChnage, label = 'คลิ๊ก' }) => {

    const [isChecked, setIsChecked] = useState(checked);

    const handleCheckboxChange = () => {
        const value = !isChecked
        setIsChecked(value);
        onChnage && onChnage(value)
    };

    return (
        <label className="flex items-start space-x-2">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <span className="text-gray-700 w-fit">{label}</span>
        </label>
    );
};

export default Checkbox;
