import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import documentIcon from '../../assets/svg/document-verify-icon.svg'

export default function SuccessModal({ title = 'Ammmm...?', subtitle = '', footerClose = 'Close', onCloseModal }) {

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all p-4">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 flex gap-6 py-6"
                                >
                                    <img src={documentIcon} alt={documentIcon} className='flex-none w-16 md:w-20 lg:24 h-auto' />
                                    <div className='flex flex-col space-y-2'>
                                        <div className='font-bold'>{title}</div>
                                        <div className='text-sm'>{subtitle}</div>
                                    </div>
                                </Dialog.Title>

                                <div className="flex justify-end gap-6">
                                    <button
                                        type="button"
                                        className='btn-primary'
                                        onClick={() => onCloseModal()}
                                    >
                                        {footerClose}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

    )
}
