import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderNo: '',
    shopId: ''
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrder: (state, action) => {
            state.orderNo = action.payload;
        },
        setShop: (state, action) => {
            state.shopId = action.payload;
        }
    },
});

export const { setOrder, setShop } = orderSlice.actions;

export default orderSlice.reducer;