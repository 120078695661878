import React, { useState } from 'react';
import logo from "../../assets/logo/remax-icon.png";
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../components/checkbox/CheckBox';

function Condition() {

    const [isAccepted, setIsAccepted] = useState(false)
    const navigate = useNavigate();

    const handleNextPage = () => {
        navigate('request');
    }

    return (
        <div className="content py-8">
            <div className='flex justify-start'>
                <img src={logo} alt="remax" className='self-center h-6 xl:h-8' />
            </div>
            <div className="py-8 flex flex-col gap-6">
                <div className="text-center text-xl font-semibold">เงื่อนไขและขั้นตอน<br />การขอใบกำกับภาษี</div>
                <div className="card space-y-4 p-2 md:p-8">
                    <div className="">
                        <div className="font-semibold mb-2">เงื่อนไข</div>
                        <ol className="list-disc list-inside">
                            <li>สามารถแจ้งขอใบกำกับภาษีภายในวันที่ซื้อสินค้าเท่านั้น ไม่สามารถออกใบกำกับภาษีย้อนหลังได้</li>
                            <li>การออกใบกำกับภาษี (Tax Invoice) จะดำเนินการหลังจากที่การจ่ายเงินเสร็จสิ้นแล้ว</li>
                            <li>สำหรับคำสั่งซื้อแบบเก็บเงินปลายทาง (COD) ไม่สามารถออกใบกำกับภาษี (Tax invoice) ได้</li>
                            <li>บริษัทฯ ไม่สามารถแก้ไขหรือเปลี่ยนแปลงข้อมูลใบกำกับภาษี (Tax invoice) ได้ โปรดตรวจสอบข้อมูลให้ถูกต้อง</li>
                        </ol>
                    </div>
                    <div className="">
                        <div className="font-semibold mb-2">ขั้นตอนการดำเนินการ</div>
                        <ol className="list-decimal list-inside">
                            <li>อ่านและรับทราบเงื่อนไขการขอใบกำกับภาษี แล้วกด "ดำเนินการต่อ"</li>
                            <li>กรอกข้อมูลให้ครบถ้วนตามแบบฟอร์ม</li>
                            <li>ตรวจสอบความถูกต้องของข้อมูล</li>
                            <li>คลิก "บันทึกข้อมูล"</li>
                            <li>รอระบบดำเนินการภายใน 7 วัน</li>
                        </ol>
                    </div>
                    <div className='list-decimal list-inside'>
                        <p>* หลังจากระบบดำเนินการเสร็จสิ้น ใบกำกับภาษีจะถูกส่งไปที่อีเมล</p>
                    </div>
                </div>
                <Checkbox
                    label='ข้าพเจ้าได้อ่านและรับทราบเงื่อนไขการขอใบกำกับภาษีแล้ว'
                    checked={isAccepted}
                    onChnage={(checked) => setIsAccepted(checked)}
                />
                <div className="flex justify-end w-full">
                    <button
                        disabled={!isAccepted}
                        className='btn-primary w-full xl:max-w-xs'
                        onClick={() => handleNextPage()}>
                        ดำเนินการต่อ
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Condition;;