import './App.css';
import { useLocation, useRoutes } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Condition from './pages/condition';
import Request from './pages/request';
import { useDispatch } from 'react-redux';
import { setUser } from './utils/userSlice';
// import { login } from './services/Liff';
import liff from '@line/liff';
import LoadingModal from './components/loading-modal/ConfirmModal';
import { getAccessToken } from './services/Auth';
import { setAuth } from './utils/authSlice';
import { liffId } from './utils/Utils';
import { setOrder, setShop } from './utils/orderSlice';

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
	const [vh, setVh] = useState(0)
	const [isloading, setIsloading] = useState(true)

	const dispatch = useDispatch();
	// const [profile, setProfile] = useState();
	let query = useQuery();

	const orderNo = query.get("orderNo");
	const shopId = query.get("shopId");
	if (orderNo) {
		dispatch(setOrder(orderNo))
	}
	if (shopId) {
		dispatch(setShop(shopId))
	}

	useEffect(() => {
		const initializeLiff = async () => {
			try {
				await liff.init({ liffId: liffId });
				if (!liff.isLoggedIn()) {
					const redirectUri = window.location.href;
					liff.login({ redirectUri: redirectUri });
				} else {
					const info = await liff.getProfile();
					dispatch(setUser(info))
					getAccessToken(info.userId, 'line').then(resultToken => {
						dispatch(setAuth({ token: resultToken.access_token }))
						localStorage.setItem('token', resultToken.access_token);
						setIsloading(false)
					})
				}
				// login().then((result) => {
				// 	setTimeout(() => {
				// 		liff.getProfile().then(profile => {
				// 			dispatch(setUser(profile))
				// 			getAccessToken(profile.userId, 'line').then(resultToken => {
				// 				dispatch(setAuth({ token: resultToken.access_token }))
				// 				localStorage.setItem('token', resultToken.access_token);
				// 				setIsloading(false)
				// 			})
				// 		})
				// 	}, 1500);
				// });
			} catch (error) {
				console.error('Error initializing LIFF', error);
			}
		};

		initializeLiff();
	}, []);


	useEffect(() => {
		if (!(typeof window === "undefined")) {

			const setHeight = () => {
				setVh(window.innerHeight)
			}

			window.addEventListener('resize', setHeight)
			setHeight()

			return () => {
				window.removeEventListener('resize', setHeight)
			}
		}
	}, [])


	return (
		<div style={{ height: `${vh}px` }} className="w-full flex-1 overflow-y-auto">
			<div className="h-full relative z-10">
				{isloading
					? <LoadingModal />
					: <Main />}
			</div>
		</div>
	);
}

function Main() {

	const routes = useRoutes([
		{ path: '/', element: <Condition /> },
		{ path: '/home', element: <Condition /> },
		{ path: '/condition', element: <Condition /> },
		{ path: '/request', element: <Request /> },
		{ path: '*', element: <Condition /> }
	]);

	return routes;

}

export default App;
