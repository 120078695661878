import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    token: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.token = action.payload.token === undefined ? '' : action.payload.token;
        }
    },
})

export const { setAuth } = authSlice.actions

export default authSlice.reducer