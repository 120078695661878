import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: '',
    displayName: '',
    pictureUrl: '',
    statusMessage: ''
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userId = action.payload.userId ? action.payload.userId : state.userId;
            state.displayName = action.payload.displayName ? action.payload.displayName : state.displayName;
            state.pictureUrl = action.payload.pictureUrl ? action.payload.pictureUrl : state.pictureUrl;
            state.statusMessage = action.payload.statusMessage ? action.payload.statusMessage : state.statusMessage;
        }
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;