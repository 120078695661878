import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import logo from "../../assets/logo/remax-icon.png";
import { useNavigate } from 'react-router-dom';
import thailandProvinces from "../../assets/data/thailand.json";
import FileUpload from '../../components/file-upload/FileUpload';
import ConfirmModal from '../../components/confirm-modal/ConfirmModal';
import LoadingModal from '../../components/loading-modal/ConfirmModal';
import SuccessModal from '../../components/success-modal/SuccessModal';
import { createRequest, getLastInvoiceUser, uploadFile } from '../../services/RequestTaxInvoiceAPI';
import DangerModal from '../../components/danger-modal/DangerModal';
import { getBranch } from '../../services/Branch';
import { useSelector } from 'react-redux';

function FormInvoice({ defaultAddress, branches }) {

    const navigate = useNavigate();
    const [modal, setModal] = useState(null)
    const [files, setFiles] = useState([]);
    const order = useSelector((state) => state.order);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        watch,
        formState: { errors }
    } = useForm();

    const initialFormAddress = Object.freeze({
        address: '',
        province: '',
        district: '',
        sub_district: '',
        postal_code: ''
    });

    const shopBranchList = branches.filter(e => e.active);

    useEffect(() => {
        setValue('type', 'individual');
        register('image_file', { required: { value: true, message: '* กรุณาอัพโหลดภาพคำสั่งซื้อ' } })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (order.orderNo) {
            setValue('order_no', order.orderNo);
        }
        if (order.shopId) {
            setValue('marketplace', 'shop');
            setTimeout(() => {
                setValue('shop_branch', order.shopId);
                clearErrors('image_file');
                register('image_file', { required: false });
            }, 1000);
        }
    }, [order]);

    useEffect(() => {
        if (defaultAddress && defaultAddress.id) {
            updateFormAddress({
                address: defaultAddress.address,
                province: defaultAddress.province,
                district: defaultAddress.district,
                sub_district: defaultAddress.sub_district,
                postal_code: defaultAddress.postal_code
            })

            setValue('type', defaultAddress.type);
            setValue('tax_id', defaultAddress.tax_id);
            if (defaultAddress?.name) setValue('name', defaultAddress.name);
            if (defaultAddress?.company_name) setValue('company_name', defaultAddress.company_name);
            if (defaultAddress?.company_branch) setValue('company_branch', defaultAddress.company_branch);

            setValue('contact_name', defaultAddress.contact_name);
            setValue('contact_phone', defaultAddress.contact_phone);
            setValue('contact_email', defaultAddress.contact_email);

            setValue('address', defaultAddress.address);
            setValue('province', defaultAddress.province);
            setTimeout(() => {
                setValue('district', defaultAddress.district);
                setValue('sub_district', defaultAddress.sub_district);
                setValue('postal_code', defaultAddress.postal_code);
            }, 1000);
        }
    }, [defaultAddress]);

    useEffect(() => {
        setValue('image_file', files, { shouldValidate: true })
    }, [files]);

    const getStartOrderNo = (shopId) => {
        if (order.orderNo) {
            return ''
        } else if (shopId) {
            const shop = shopBranchList.find(e => e.shop_id === shopId)
            return shop?.bill_no || '';
        }
        return '';
    }

    const handleSetLoading = (loading) => {
        if (loading) {
            console.log('กำลังอัพโหลดไฟล์..')
        } else {
            console.log('อัพโหลดเสร็จแล้ว')
        }
    }

    const handleSetFiles = (files) => {
        if (files) {
            let filesNotOk = false;
            for (const file of files) {
                const filesize = ((file.size / 1024) / 1024).toFixed(4); // to MB
                if (filesize > 10) {
                    filesNotOk = true;
                    alert('ไฟล์ขนาดใหญ่เกิน')
                }
            }
            if (!filesNotOk) {
                setFiles(files);
            }
        }
    }

    const removeFile = (id) => {
        setFiles([])
    }

    const handleChangeMarketplace = (event) => {
        setValue('marketplace', event.target.value);
    }

    const handleChangeShopBranch = (event) => {
        setValue('shop_branch', event.target.value);
    }

    const [formAddress, updateFormAddress] = useState(initialFormAddress);
    const handleChangeAddress = (e) => {
        console.log(e)
        updateFormAddress({
            ...formAddress,
            [e.target.name]: e.target.value.trim()
        });
    };

    const onSubmit = (formData) => {
        setModal(
            <ConfirmModal
                title={'ขอใบกำกับภาษี'}
                subtitle={'หากดำเนินการแล้วจะไม่สามารถ แก้ไข/ยกเลิก ได้ โปรดตรวจสอบข้อมูลความถูกต้อง'}
                footerClose={'ยกเลิก'}
                footerComfirm={'ยืนยัน'}
                onCloseModal={() => setModal(null)}
                handleConfirm={() => handleConfirm(formData)}
            />
        )
    }

    const handleConfirm = async (formData) => {
        setModal(<LoadingModal />)
        console.log(formData)
        let image_url = '';
        if (files.length > 0) {
            const dataImage = { file: files[0].data.url, type: files[0].data.extFile }
            const resultImage = await uploadFile(dataImage)
            console.log(resultImage)
            if (!resultImage.success) {
                setModalError()
                return;
            };
            image_url = resultImage.data.link
        }

        let orderNo = formData.order_no;
        let shop_branch = formData.shop_branch;
        if (formData.marketplace === 'shop') {
            const billStart = getStartOrderNo(shop_branch);
            orderNo = billStart + formData.order_no
        }

        const dataCreate = {
            ...formData,
            postal_code: `${thailandProvinces[formAddress.province][formAddress.district][formAddress.sub_district]}`,
            image_url,
            order_no: orderNo,
            shop_branch: shopBranchList.find(e => e.shop_id === formData.shop_branch)?.name
        }
        createRequest(dataCreate).then(response => {
            console.log(response)
            if (response.success) {
                setModal(<SuccessModal
                    title={'บันทึกข้อมูลสำเร็จ'}
                    subtitle={'ระบบจะดำเนินการและส่งใบกำกับทางอีเมล ขอบคุณค่ะ'}
                    footerClose={'ปิด'}
                    onCloseModal={() => { setModal(null); navigate('/'); }}
                />)
            } else {
                setModalError()
            }
        })

    }

    const setModalError = () => {
        setModal(<DangerModal
            title={'ไม่สำเร็จ'}
            subtitle={'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่'}
            footerClose={'ปิด'}
            onCloseModal={() => setModal(null)}
        />)
    }

    const onError = (data) => {
        console.log(data)
    }

    return (<>
        {modal}
        <form onSubmit={handleSubmit(onSubmit, onError)} className=''>
            <div className='card space-y-4 p-2 md:p-8'>
                <div className="space-y-4">
                    <div className='font-bold text-lg'>
                        <div>1. ข้อมูลใบกำกับภาษี</div>
                    </div>
                    <div className="flex space-x-6 items-center w-full py-2">
                        <div className="flex items-center">
                            <input id="type-individual" type="radio" value="individual" className="mr-2" {...register('type')} />
                            <label htmlFor="type-individual">บุคคลธรรมดา</label>
                        </div>
                        <div className="flex items-center">
                            <input id="type-corporate" type="radio" value="corporate" className="mr-2" {...register('type')} />
                            <label htmlFor="type-corporate">นิติบุคคล</label>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">

                        {watch('type') === 'corporate' && <>
                            <div className="col-span-1">
                                <label>* เลขประจำตัวผู้เสียภาษี <span className='text-sm'>(บริษัท)</span></label>
                                <div className="">
                                    <input name="tax_id" {...register('tax_id')} required autoComplete='off' className="input" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label>* ชื่อบริษัท</label>
                                <div className="">
                                    <input name="company_name" {...register('company_name')} required autoComplete='off' className="input" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label>* สาขา <span className='text-sm'>(หากไม่มีใส่ขีด -)</span></label>
                                <div className="">
                                    <input name="company_branch" {...register('company_branch')} required autoComplete='off' className="input" />
                                </div>
                            </div>
                        </>}

                        {watch('type') === 'individual' && <>
                            <div className="col-span-1">
                                <label>* เลขประจำตัวผู้เสียภาษี <span className='text-sm'>(บัตรประชาชน/พาสปอร์ต)</span></label>
                                <div className="">
                                    <input name="tax_id" {...register('tax_id')} required autoComplete='off' className="input" />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <label>* ชื่อ-สกุล</label>
                                <div className="">
                                    <input name="name" {...register('name')} required autoComplete='off' className="input" />
                                </div>
                            </div>
                        </>}

                        <div className="col-span-1 md:col-span-2">
                            <label>* ที่อยู่</label>
                            <div className="">
                                <input name="address" {...register('address')} required autoComplete='off' className="input" />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label>* จังหวัด</label>
                            <div className="select-container">
                                <select name="province" {...register('province')} required onChange={handleChangeAddress} className="">
                                    <option value=""></option>
                                    {
                                        Object.keys(thailandProvinces).map(element => {
                                            return (<option key={element}>{element}</option>);
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label>* เขต/อำเภอ</label>
                            <div className='select-container'>
                                <select name="district"  {...register('district')} required onChange={handleChangeAddress} className="">
                                    <option value=""></option>
                                    {
                                        (formAddress.province !== '') &&
                                        Object.keys(thailandProvinces[formAddress.province]).map(element => {
                                            return (<option key={element}>{element}</option>);
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label>* แขวง/ตำบล</label>
                            <div className='select-container'>
                                <select name="sub_district" {...register('sub_district')} required onChange={handleChangeAddress} className="">
                                    <option value=""></option>
                                    {
                                        (formAddress.province !== '' && formAddress.district !== '' && thailandProvinces[formAddress.province][formAddress.district] !== undefined) &&
                                        Object.keys(thailandProvinces[formAddress.province][formAddress.district]).map(element => {
                                            return (<option key={element}>{element}</option>);
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label>* รหัสไปรษณีย์</label>
                            <div className="">
                                <input type="text" id='postal_code' name="postal_code" {...register('postal_code')} onChange={handleChangeAddress} disabled
                                    value={formAddress.province !== '' && formAddress.district !== '' && formAddress.sub_district !== '' &&
                                        thailandProvinces[formAddress.province][formAddress.district] !== undefined &&
                                        thailandProvinces[formAddress.province][formAddress.district][formAddress.sub_district] !== undefined ? thailandProvinces[formAddress.province][formAddress.district][formAddress.sub_district] : ''}
                                    className="input" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="w-full border-b-2 border-dashed my-10"></div>

                <div className="space-y-4">
                    <div className='font-bold text-lg'>
                        <div>2. ข้อมูลสำหรับติดต่อ</div>
                    </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="col-span-1">
                            <label>* ชื่อ-สกุล</label>
                            <div className="">
                                <input name="contact_name" {...register('contact_name')} required autoComplete='off' className="input" />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label>* โทรศัพท์ <span className='text-sm'>(ไม่ต้องใส่ขีด)</span></label>
                            <div className="">
                                <input name="contact_phone" {...register('contact_phone')} required autoComplete='off' type="tel" minLength={10} maxLength={10} placeholder="" className="input" />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label>* E-mail</label>
                            <div className="">
                                <input name="contact_email" {...register('contact_email')} required autoComplete='off' type="email" placeholder="" className="input" />
                            </div>
                        </div>
                    </div>
                    <div className="text-red-500 text-sm">* เอกสารใบกำกับภาษีจะถูกส่งไปที่ email: {watch('contact_email')}</div>
                </div>

                <div className="w-full border-b-2 border-dashed my-10"></div>

                <div className="space-y-4">
                    <div className='font-bold text-lg'>
                        <div>3. ข้อมูลคำสั่งซื้อ</div>
                    </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="col-span-1">
                            <label>* ช่องทางการสั่งซื้อ</label>
                            <div className='select-container'>
                                <select name="marketplace" {...register('marketplace')} required onChange={handleChangeMarketplace} className="">
                                    <option value="shop">ร้านค้า Remax Thailand</option>
                                    <option value="website">Website Remax Thailand</option>
                                </select>
                            </div>
                        </div>
                        {watch('marketplace') === 'shop' && <>
                            <div className="col-span-1">
                                <label>* สาขา</label>
                                <div className='select-container'>
                                    <select name="shop_branch" {...register('shop_branch')} required onChange={handleChangeShopBranch} className="">
                                        <option></option>
                                        {shopBranchList.map((item, index) => {
                                            return <option key={index} value={item.shop_id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </>}
                        <div className="col-span-1">
                            <label>* หมายเลขคำสั่งซื้อ</label>
                            {watch('marketplace') === 'shop' ? <>
                                <div className="">
                                    <div className="input flex flex-row items-center space-x-1">
                                        <div className="text-gray-500">{getStartOrderNo(watch('shop_branch'))}</div>
                                        <input name="order_no" {...register('order_no')} required autoComplete='off' placeholder="" className='w-full h-full bg-transparent focus:outline-none' onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                                    </div>
                                </div>
                            </> : <>
                                <div className="">
                                    <input name="order_no" {...register('order_no')} required autoComplete='off' placeholder="" className="input" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                                </div>
                            </>}
                        </div>
                        <div className="col-span-1 col-start-1">
                            <label>* แนบภาพคำสั่งซื้อ</label>
                            {files.length === 0 && <FileUpload
                                setFiles={handleSetFiles}
                                accept={['jpg', 'png', 'jpeg']}
                                setIsloading={handleSetLoading} />}
                            {files.length > 0 && <>
                                <div className='w-full md:max-w-72 relative' tabIndex={0} key={files[0].id} >
                                    <img className="w-full rounded-md" src={files[0].data.url} alt="preview" />
                                    <button type='button' className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:shadow transition duration-300" onClick={() => removeFile()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </>}
                            <div className='text-red-500 text-sm mt-2'>{errors?.image_file?.message}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 xl:mt-8 flex flex-col lg:flex-row gap-4 justify-center w-full">
                <button type='button' className='btn-secondary w-full xl:max-w-xs' onClick={() => navigate('/')}>ย้อนกลับ</button>
                <button type='submit' className='btn-primary w-full xl:max-w-xs'>บันทึกข้อมูล</button>
            </div>
        </form>
    </>)
}

function Request() {
    const user = useSelector((state) => state.user);
    const [defaultAddress, setDefaultAddress] = useState()
    const [branches, setBranches] = useState([])

    useEffect(() => {
        getBranch().then(res => {
            if (res.data) {
                setBranches(res.data.sort((a, b) => a.name.localeCompare(b.name)))
            }
        })
    }, [])

    useEffect(() => {
        getLastInvoiceUser().then(res => {
            if (res) {
                setDefaultAddress(res.data)
            }
        })
    }, [user]);

    return (
        <div className="content py-8">
            <div className='flex justify-start'>
                <img src={logo} alt="remax" className='self-center h-6 xl:h-8' />
            </div>
            <div className="py-8 flex flex-col gap-6">
                <div className="text-center text-xl font-semibold">แบบฟอร์ม<br />ขอใบกำกับภาษี</div>
                <FormInvoice defaultAddress={defaultAddress} branches={branches} />
            </div>
        </div>
    );
}

export default Request;