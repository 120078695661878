import axios from 'axios';
import { endPoint } from '../utils/Utils';

function header() {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    return { headers };
}

export async function createRequest(body) {
    return new Promise((resolve, reject) => {
        axios.post(`${endPoint}/request-tax-invoice`, body, header()).then(response => {
            resolve(response.data);
        }).catch(err => {
            console.log(err);
            resolve({});
        })
    })
}

export async function uploadFile(body) {
    return new Promise((resolve, reject) => {
        axios.post(`${endPoint}/request-tax-invoice/uploadImage`, body, header()).then(response => {
            resolve(response.data);
        }).catch(err => {
            console.log(err);
            resolve({});
        })
    })
}

export async function getLastInvoiceUser() {
    return new Promise((resolve, reject) => {
        axios.get(`${endPoint}/request-tax-invoice/last`, header()).then(response => {
            resolve(response.data);
        }).catch(err => {
            console.log(err);
            resolve({});
        })
    })
}
