import { configureStore } from '@reduxjs/toolkit';
import userReducer from './utils/userSlice';
import authReducer from './utils/authSlice';
import orderReducer from './utils/orderSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    order: orderReducer,
  },
})