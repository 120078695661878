import axios from 'axios';
import { authEndPoint } from '../utils/Utils';

export async function getAccessToken(user_id, channel) {
    return new Promise((resolve, _reject) => {
        axios.post(`${authEndPoint}/auth/token`, { user_id, channel }).then(response => {
            resolve(response.data)
        }).catch(err => {
            console.log(err);
            resolve({});
        })
    })
}